<template>
<div>
  <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{ $trans('Web Contents') }}
      </span>
    <button @click="addForm = true" class="btn btn-primary btn-sm mr-3">
      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
           stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
        <line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line>
        <line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line>
      </svg>
      {{ $trans('Add new') }}
    </button>
    <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
      <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
           stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
      </svg>
      {{ $trans('Filter') }}
    </button>
  </h4>
  <div v-if="formFilter" class="card mb-3">
    <div class="card-body">
      <div class="d-flex">
        <div class="form-group mr-3">
          <label>{{ $trans('Keyword') }}</label>
          <input v-model="filterQuery.keyword" :placeholder="'keyword here!'" type="text" class="form-control">
        </div>
        <div class="form-group mr-3">
          <label>{{ $trans('Partner') }}</label>
          <Select2 class="w-200" v-model="filterQuery.partner_id" :options="partners"/>
        </div>
        <div class="form-group mr-3">
          <label class="d-block">{{ $trans('From date') }}</label>
          <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.from"></vuejs-datepicker>
        </div>
        <div class="form-group  mr-3">
          <label class="d-block">{{ $trans('To date') }}</label>
          <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.to"></vuejs-datepicker>
        </div>
      </div>
      <div class="filter-actions d-flex">
        <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
        <button @click="index" class="btn btn-outline-primary btn-sm mr-3">
          <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Apply') }}
        </button>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div v-if="contents && contents.data" class="table-responsive">
        <div class="list-info mb-3">
          <small>{{ $trans('Results') }}: <strong>{{ contents.data.length }}</strong> {{ $trans('of') }}
            <strong>{{ contents.total }}</strong></small>
        </div>
        <table class="table table-striped mb-3">
          <thead>
          <tr>
            <th>{{ $trans('Ref Number') }}</th>
            <th>{{ $trans('Date') }}</th>
            <th>{{ $trans('Creator') }}</th>
            <th>{{ $trans('Partner') }}</th>
            <th class="text-right">{{ $trans('Amount') }}</th>
            <th>{{ $trans('Note') }}</th>
            <th>{{ $trans('Status') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(content,index) in contents.data" v-bind:key="index">
            <td class="link" @click="detail(content)">{{ content.ref_number }}</td>
            <td>{{ content.date }}</td>
            <td>
              <slot v-if="content.user">{{ content.user.username }}</slot>
            </td>
            <td>
              <slot v-if="content.partner">{{ content.partner.name }}</slot>
            </td>
            <td class="text-right">
              <vue-numeric
                  currency-symbol-position="suffix"
                  currency=""
                  v-model="content.amount"
                  v-bind:read-only="true"
                  :minus="true"
                  v-bind:precision="0">
              </vue-numeric>
            </td>
            <td>{{ content.note }}</td>
            <td>
              <request-status :status="content.status"></request-status>
            </td>

            <td class="text-right td-control">
              <slot v-if="user && user._id == content.user_id && content.status ==0">
                <a @click="edit(content)" class="mr-1" href="javascript:void(0)">
                  <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </a>
                <a @click="destroy(content)" class="text-danger" href="javascript:void(0)">
                  <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </a>
              </slot>
            </td>
          </tr>
          </tbody>
        </table>
        <paginate v-if="contents && contents.data && contents.last_page"
                  :page-count="contents.last_page"
                  :click-handler="paginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </div>
    </div>
  </div>
  <modal v-if="addForm" v-bind:width="'800px'" v-bind:height="'100vh'">
    <slot slot="body">
      <h5 class="mb-3">
        {{ $trans('Add request') }}
        <button @click="addForm = false" class="close">
          <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
               stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </h5>
      <small>{{ $trans('Fields Marked with (*) are required') }}</small>
      <hr>
      <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
        {{ alert.message }}
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>{{ $trans('Partner') }} (*)</label>
            <Select2 class="" v-model="addFields.partner_id" :options="partners"/>
            <slot v-if="errors && errors['partner_id']">
              <div class="text-danger" v-for="error in errors['partner_id']"
                   v-bind:key="error">
                <small>{{ error }}</small>
              </div>
            </slot>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label>{{ $trans('Detail') }} (*)</label>
            <table class="table-striped table">
              <thead>
              <tr>
                <th>{{$trans('Project') }}</th>
                <th>{{ $trans('Qty')}}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,k) in detail_table" :key="k">
                <td>
                  <Select2 class="w-200" v-model="detail_table[k]['project_id']" :options="projects"/>
                </td>
                <td>
                  <vue-numeric class="form-control"
                               currency-symbol-position="suffix"
                               currency=""
                               v-model="detail_table[k]['qty']"
                               v-bind:read-only="false"
                               :minus="true"
                               v-bind:precision="3">
                  </vue-numeric>
                </td>
                <td class="text-right">
                  <a @click="removeDetail(k)" href="javascript:void(0)">
                    {{ $trans('Remove') }}
                  </a>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="2"></td>
                <td class="text-right">
                  <a @click="addDetail()" href="javascript:void(0)">
                    {{ $trans('Add') }}
                  </a>
                </td>
              </tr>
              </tfoot>
            </table>
            <slot v-if="errors && errors['detail']">
              <div class="text-danger" v-for="error in errors['detail']"
                   v-bind:key="error">
                <small>{{ error }}</small>
              </div>
            </slot>
          </div>
          <div class="form-group">
            <label>{{ $trans('Note') }} (*)</label>
            <textarea class="form-control" v-model="addFields.note"></textarea>
          </div>
        </div>
      </div>
      <button @click="store" class="btn btn-primary">
        <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
        {{ $trans('Add') }}
      </button>
    </slot>
  </modal>
  <modal v-if="editForm" v-bind:width="'800px'" v-bind:height="'100vh'">
    <slot slot="body">
      <h5 class="mb-3">
        {{ $trans('Edit request') }}
        <button @click="editForm = false" class="close">
          <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
               stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </h5>
      <small>{{ $trans('Fields Marked with (*) are required') }}</small>
      <hr>
      <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
        {{ alert.message }}
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>{{ $trans('Partner') }} (*)</label>
            <Select2 class="" v-model="updateFields.partner_id" :options="partners"/>
            <slot v-if="errors && errors['partner_id']">
              <div class="text-danger" v-for="error in errors['partner_id']"
                   v-bind:key="error">
                <small>{{ error }}</small>
              </div>
            </slot>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label>{{ $trans('Detail') }} (*)</label>
            <table class="table-striped table">
              <thead>
              <tr>
                <th>{{$trans('Project') }}</th>
                <th>{{ $trans('Qty')}}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,k) in detail_table" :key="k">
                <td>
                  <Select2 class="w-200" v-model="detail_table[k]['project_id']" :options="projects"/>
                </td>
                <td>
                  <vue-numeric class="form-control"
                               currency-symbol-position="suffix"
                               currency=""
                               v-model="detail_table[k]['qty']"
                               v-bind:read-only="false"
                               :minus="true"
                               v-bind:precision="3">
                  </vue-numeric>
                </td>
                <td class="text-right">
                  <a @click="removeDetail(k)" href="javascript:void(0)">
                    {{ $trans('Remove') }}
                  </a>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="2"></td>
                <td class="text-right">
                  <a @click="addDetail()" href="javascript:void(0)">
                    {{ $trans('Add') }}
                  </a>
                </td>
              </tr>
              </tfoot>
            </table>
            <slot v-if="errors && errors['detail']">
              <div class="text-danger" v-for="error in errors['detail']"
                   v-bind:key="error">
                <small>{{ error }}</small>
              </div>
            </slot>
          </div>
          <div class="form-group">
            <label>{{ $trans('Note') }} (*)</label>
            <textarea class="form-control" v-model="updateFields.note"></textarea>
          </div>
        </div>
      </div>
      <button @click="update" class="btn btn-primary">
        <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
        {{ $trans('Update') }}
      </button>
    </slot>
  </modal>
  <modal v-if="detailForm" v-bind:width="'800px'" v-bind:height="'100vh'">
    <slot slot="body">
      <h5 class="mb-3">
        {{ $trans('Request Detail') }}
        <button @click="detailForm = false" class="close">
          <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
               stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </h5>
      <table class="table table-striped">
        <tbody>
        <tr>
          <th class="w-200">{{ $trans('Ref Number') }}</th>
          <td>{{ content.ref_number }}</td>
        </tr>
        <tr>
          <th>{{ $trans('Creator') }}</th>
          <td>
            <slot v-if="content.user">{{ content.user.username }}</slot>
          </td>
        </tr>
        <tr>
          <th>{{ $trans('Partner') }}</th>
          <td>
            <slot v-if="content.partner">{{ content.partner.name }}</slot>
          </td>

        </tr>
        <tr>
          <th>{{ $trans('Amount') }}</th>
          <td>
            <vue-numeric
                currency-symbol-position="suffix"
                currency=""
                v-model="content.amount"
                v-bind:read-only="true"
                :minus="true"
                v-bind:precision="0">
            </vue-numeric>
          </td>
        </tr>
        <tr>
          <th>{{ $trans('Status') }}</th>
          <td>
            <request-status :status="content.status"></request-status>
          </td>
        </tr>
        <tr>
          <th>{{ $trans('Date') }}</th>
          <td>{{ content.date }}</td>
        </tr>
        <tr>
          <th>{{ $trans('Note') }}</th>
          <td>{{ content.note }}</td>
        </tr>
        </tbody>
      </table>
      <h5>{{ $trans('Detail') }}</h5>
      <table class="table-striped table">
        <thead>
        <tr>
          <th>{{ $trans('Project') }}</th>
          <th>{{ $trans('Qty') }}</th>
          <th>{{ $trans('Price') }}</th>
          <th>{{ $trans('Total') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,k) in content.detail" :key="k">
          <td>{{project_list[item.project_id]}}</td>
          <td>{{item.qty}}</td>
          <td>
            <vue-numeric
                currency-symbol-position="suffix"
                currency=""
                v-model="item.price"
                v-bind:read-only="true"
                :minus="true"
                v-bind:precision="0">
            </vue-numeric>
          </td>
          <td>
            <vue-numeric
                currency-symbol-position="suffix"
                currency=""
                v-model="item.total"
                v-bind:read-only="true"
                :minus="true"
                v-bind:precision="0">
            </vue-numeric>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="form-actions">
        <slot v-if="content && content.status == 0">
          <button @click="confirm_request(content)" class="btn btn-primary mr-3">
            <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Confirm & Request payment') }}
          </button>
        </slot>
      </div>
    </slot>
  </modal>
</div>
</template>
<script>
import axios from "axios";

export default {
  name: "Content",
  data:function () {
    return {
      addForm:false,
      editForm:false,
      formFilter:false,
      process_loading:false,
      process:false,
      alert:null,
      errors:{},
      addFields:{
        note:'',
        partner_id:''
      },
      detail_table:[],
      filterQuery:{
        page:1,
        from: null,
        to: new Date(),
        keyword:'',
        partner_id:''
      },
      contents:null,
      updateFields:null,
      detailForm:false,
      content:null
    }
  },
  mounted() {
    var today = new Date();
    this.filterQuery.from = new Date(today.getFullYear(), today.getMonth(), 1)
  },
  methods:{
    reset_filter: function () {
      var today = new Date();
      this.filterQuery = {
        from: new Date(today.getFullYear(), today.getMonth(), 1),
        to: new Date(),
        page: 1,
        partner_id: '',
        keyword: '',
      }
      this.index()
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index:function () {
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/contents',params:this.filterQuery, method: 'GET'})
          .then(resp => {
            this.contents = resp.data;
            this.process_loading = false
          })
          .catch(err => {
            console.log(err)
            this.process_loading = false
          })
    },
    store: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.addFields
        data.detail = this.detail_table
        axios({url: this.$root.$data.api_url + '/api/contents', data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.addForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    edit:function (order){
      axios({url: this.$root.$data.api_url + '/api/contents/' + order._id, method: 'GET'})
          .then(resp => {
            this.updateFields = resp.data.data;
            this.detail_table = resp.data.data.detail
            this.editForm = true;
          })
          .catch(err => {
            console.log(err)
          })
    },
    update:function (){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.updateFields
        data.detail = this.detail_table
        axios({url: this.$root.$data.api_url + '/api/contents/' + data._id, data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    addDetail: function () {
      let item = {
        project_id: '',
        qty: 0,
        price: 0,
        total: 0
      }

      let items = this.detail_table
      items.push(item)
      this.detail_table = items
    },
    removeDetail: function (index) {
      let items = this.detail_table
      items.splice(index, 1);

      this.detail_table = items
    },
    get_projects: function () {
      axios({url: this.$root.$data.api_url + '/api/user/projects', params: {}, method: 'GET'})
          .then(resp => {
            this.projects_res = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    get_partners: function () {
      axios({url: this.$root.$data.api_url + '/api/user/partners', params: {}, method: 'GET'})
          .then(resp => {
            this.partners_res = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    destroy:function (request){
      var actionConfirm = confirm("Are you sure?");
      if (actionConfirm === false) {
        return false;
      }
      axios({url: this.$root.$data.api_url + '/api/contents/' + request._id, method: 'DELETE'})
          .then(() => {
            this.index();
          })
          .catch(err => {
            console.log(err)
          })
    },
    detail:function (order){
      axios({url: this.$root.$data.api_url + '/api/contents/' + order._id, method: 'GET'})
          .then(resp => {
            this.content = resp.data.data;
            this.detailForm = true;
          })
          .catch(err => {
            console.log(err)
          })
    },
    confirm_request:function (request) {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = {}
        axios({url: this.$root.$data.api_url + '/api/contents/confirm/' + request._id, data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.addForm = false;
                this.index();
                this.detail(request)
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    }
  },
  created() {
    this.index()
    this.get_projects()
    this.get_partners()
  },
  computed:{
    projects: function () {
      if (!this.projects_res) {
        return [];
      }
      let projects = [];
      let projects_res = this.projects_res;
      projects_res.forEach((el) => {
        let item = {
          id: el._id,
          text: el.name
        }
        projects.push(item)
      })

      return projects;
    },
    partners: function () {
      if (!this.partners_res) {
        return [];
      }
      let partners = [];
      let partners_res = this.partners_res;
      partners_res.forEach((el) => {
        let item = {
          id: el._id,
          text: el.name
        }
        partners.push(item)
      })

      return partners;
    },
    user: function () {
      return this.$root.user
    },
    project_list:function () {
      if (!this.projects_res) {
        return [];
      }
      let projects = {};
      let projects_res = this.projects_res;
      projects_res.forEach((el) => {
        projects[el._id] = el.name
      })

      return projects;
    }
  }
}
</script>
<style scoped>

</style>